<template>
  <div>
    <section>
      <v-container>
        <v-row>
          <v-col cols="4">
            <div class="mb-2">
              <sup>
                <v-icon
                   size="20"
                   v-text="'mdi-crosshairs-gps'"
                />
              </sup>
              <span class="ml-2"><b>ADRES SIEDZIBY</b></span>
              <p class="mb-1">Energetyka WAGON Sp. z o.o.</p>
              <p class="mb-1">ul. Wysocka 52</p>
              <p class="mb-1">63-400 Ostrów Wielkopolski</p>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="mb-2">
              <sup>
                <v-icon
                   size="20"
                   v-text="'mdi-crosshairs-gps'"
                />
              </sup>
              <span class="ml-2"><b>KONTAKT</b></span>
              <p class="mb-1">tel: +48 62 595 3994</p>
              <p class="mb-1">tel: +48 62 595 3528</p>
              <p class="mb-1">fax: +48 62 595 3528</p>
              <p class="mb-1">email: <a href="email:kontakt@energetyka-wagon.pl">kontakt@energetyka-wagon.pl</a></p>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="mb-2">
              <sup>
                <v-icon
                   size="20"
                   v-text="'mdi-email'"
                />
              </sup>
              <span class="ml-2"><b>BIURO SPÓŁKI</b></span>
              <p class="mb-1">Małgorzata Dolska</p>
              <p class="mb-1">tel: +48 62 595 3994</p>
              <p class="mb-1">fax: +48 62 595 3528</p>
              <p class="mb-1">email: <a href="email:m.dolska@energetyka-wagon.pl">m.dolska@energetyka-wagon.pl</a></p>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="10">
            <div class="mb-2">
              <span><b>DANE DO FAKTURY:</b></span>
              <p
                class="mb-1"
                style="font-size:0.85rem;"
              >
                ENERGETYKA WAGON Sp. z o.o. zarejestrowana w Sądzie Rejonowym Poznań - Nowe Miasto i Wilda w Poznaniu IX Wydział Gospodarczy Krajowego Rejestru Sądowego pod numerem KRS: 0000275711, NIP: 6222629397, REGON: 300467250, BDO: 000573506. Wysokość kapitału zakładowego: 700 000 zł.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section class="py-0">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2476.435816122399!2d17.821470651344296!3d51.633543279556235!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ab4316f70d207%3A0xfb690cb7877363ba!2sWysocka%2052%2C%2063-400%20Ostr%C3%B3w%20Wielkopolski!5e0!3m2!1sen!2spl!4v1680366548826!5m2!1sen!2spl"
        width="100%"
        height="600"
        frameborder="0"
        style="border:0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </section>

    <!-- <section class="grey lighten-3">
      <v-form>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Your Name*"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Your Email*"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                outlined
                label="Subject"
              />
            </v-col>
            <v-col cols="12">
              <v-textarea
                outlined
                label="Your Message"
              />
            </v-col>
            <v-col text-center>
              <v-btn
                :block="$vuetify.breakpoint.smAndDown"
                class="px-5 text-capitalize"
                color="secondary"
                large
              >
                Send
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </section> -->
  </div>
</template>

<script>
  export default {
  }
</script>
